<template>
  <div>
    <div class="project-container">
		<project v-for="project of softwareProjects" :key="project.title" :project="project" class="project"></project>
	</div>
  </div>
</template>
<script>
import Project from '@/components/Project.vue'

export const SOFTWARE_PROJECTS = [
	{
		title: "Seattle Antifreeze",
		link: "https://github.com/emeersman/design-for-iot/tree/master/ClimateChangeVisualizations",
		description: "Twitter bot to display climate stripe visualizations of historical daily temperatures in Seattle."
	},
	{
		title: "Gesture Recognizer",
		link: "https://github.com/emeersman/cse590/tree/master/GestureRecognizer",
		description: "ML models that identify specific gestures from raw accelerometer datasets."
	},
	{
		title: "Twitter Tones",
		link: "https://github.com/emeersman/design-for-iot/tree/master/A4_TwitterTones",
		description: "Comparative sentiment analysis about two topics on Twitter, published to an MQTT feed and displayed on RGB LEDs."
	}
	// {
	// 	title: "Wine DSL",
	// 	link: "",
	// 	description: "TODO fix and upload to github"
	// },
	// {
	// 	title: "Flavor wheel generator",
	// 	link: "",
	// 	description: "TODO fix + serve somewhere and upload to github"
	// }
]

export default {
  name: 'Software',
  components: {
    Project
  },
  data () {
	return {
		softwareProjects: SOFTWARE_PROJECTS
	}
  }
}
</script>
<style scoped>
.project-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
</style>